import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Does Tensorflow Lite Work?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "It provides hardware acceleration. The Android NDK contains NNAPI (Neural Network API) that gives access to hardware-accelerated inference operation on devices using Android software."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Is Tensorflow So Popular?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Tensorflow is popular because it is open-source and it works in the form of a data flow graph. It offers great documentation where you can get all the important information and the latest updates."
        }
    }
}

const Tensorflow = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> TensorFlow Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top TensorFlow Developers. Top
                                    companies and start-ups choose Codestaff professional TensorFlow
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top TensorFlow Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE TENSORFLOW DEVELOPERS' banner='/tensorflow.png' bannerAlt='tensorflow banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Tensorflow